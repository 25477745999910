import Lightbulb from './images/lightbulb.png';

function App() {
  return (
    <div className="text-center">
      <div className="bg-[#F1CF14] h-screen w-screen flex items-center justify-center">
        <div className='mx-2'>
          <img className="rounded-full w-[200px] h-[200px] m-auto border-2 border-black mb-8" src={Lightbulb}/>
          <h1 className="pb-10 text-2xl font-medium">Subscribe to CoolChristian's Newsletter</h1>
          <a href="https://coolchristians-giveaway.beehiiv.com/subscribe" class="relative inline-block text-lg group" target="_blank" rel="noopener noreferrer">
            <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
            <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
            <span class="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
            <span class="relative">Subscribe</span>
            </span>
            <span class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0" data-rounded="rounded-lg"></span>
            </a>
            <div>
            <h2 className="text-xl font-medium mt-5">
              Follow me on <a href="https://www.youtube.com/@imcoolchristian" className="underline hover:text-white" target="_blank" rel="noopener noreferrer">YouTube</a>
            </h2>
            <h2 className="text-xl font-medium mt-5">
              Follow me on <a href="https://www.instagram.com/coolchristianengineer" className="underline hover:text-white" target="_blank" rel="noopener noreferrer">Instagram</a>
            </h2>
            <h2 className="text-xl font-medium mt-5">
              Follow me on <a href="https://twitter.com/imcoolchristian" className="underline hover:text-white" target="_blank" rel="noopener noreferrer">Twitter</a>
            </h2>
            
            <h2 className="pb-10 text-xl font-medium mt-10">
               Support my work on <a href="https://www.buymeacoffee.com/imcoolchristian" className="underline hover:text-white" target="_blank" rel="noopener noreferrer">Buy Me a Coffee!</a>
            </h2>
            </div>
          </div>
      </div>
    </div>
  );
}

export default App;
